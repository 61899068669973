

import {defineComponent, reactive, ref, watch} from 'vue';
import router from "@/router";
import config from "@/config";
import {useRoute} from "vue-router";
// import setPresenceState from "@/composables/setPresenceState";
import getState from "@/composables/getState";
import {auth} from "@/firebase/config";
// import {doc, collection} from "firebase/firestore";
import { useUserStore } from "@/store/useUserStore"
import useCheckExistenceOfColl from "@/composables/useCheckExistenceOfColl";
import Footer from "@/components/Footer.vue";
import Modal from "@/components/Modal.vue";
import { isMobile, browserName } from "mobile-device-detect";

export default defineComponent({
  components: {Footer, Modal},
  props: {

  },
  /**
   * @description looks for user data in store
   * and checks if the game is in progress and gets the latest room or room = 1
   */
  beforeRouteEnter(to, from, next) {
    const userStore = useUserStore()
    const projectProgress = to.meta.projectProgress as { [key: string]: boolean }
    if (userStore.getUid !== '' && userStore.playerId !== '' && userStore.playerName !== '') {
      let room = '1'
      const id = to.params.id
      const lang = userStore.getLang || ""
      if (projectProgress) {
        const currRoom = Object.keys(projectProgress).find((key:string) => projectProgress[key] === false)
        room = (currRoom) ? currRoom : '1'
      }
      router.push({
        name: 'Room',
        params: {lang, id, room}
      })
    } else {
      // go on with «Start» page
      next()
    }
  },
  setup() {
    const route = useRoute()
    const id = route.params.id
    const lang = route.params.lang as string || ''
    const playerNames = config.player.map(item => item.name)
    const playersRef = reactive(config.player as { [k: string]: any })
    const playerIdRef = ref<number | null>(null)
    const otherPlayersIndexRef = ref<number>(-1)
    const showNoteRef = ref<boolean>(false)
    const projectProgress = route.meta.projectProgress as { [key: string]: boolean }
    const userStore = useUserStore()
    const modalContent = ref<string | null>(null)
    const mobileDevice = (browserName === 'Silk') ? 'true' : isMobile.toString()
    let room = '1'
    if (projectProgress) {
      const currRoom = Object.keys(projectProgress).find((key:string) => projectProgress[key] === false)
      room = (currRoom) ? currRoom : '1'
    }

    // add current online/offline state to playersRef
    const {error: errorState, documents: stateDoc, load: loadGetState} = getState(`/games/${id}/players/`)
    loadGetState()
    watch(stateDoc, () => {
      if (stateDoc.value && !errorState.value) {
        const arrPlayersChoosen = []
        for (let key in playersRef) {
          playersRef[key].players = stateDoc.value.filter(item => item.state === 'online' && item.playerId === +key).length
          arrPlayersChoosen.push(playersRef[key].players)
        }
        const totalPlayer = arrPlayersChoosen.reduce((accumulator, value) => {
          return accumulator + value;
        }, 0);
        if (totalPlayer > 0) {
          otherPlayersIndexRef.value = arrPlayersChoosen.findIndex(i => (i === 0))
        } else {
          otherPlayersIndexRef.value = -1
        }
      }
    })

    const selectPlayer = (playerId: number) => {
      playerIdRef.value = playerId
      const activeBtn : HTMLButtonElement = itemRefs[playerId]
      activeBtn.parentElement?.classList.add('selected')
      activeBtn.classList.add('active')
      showNoteRef.value = (playerIdRef.value !== otherPlayersIndexRef.value)
    }

    const resetPlayer = (playerId: number) => {
      playerIdRef.value = null
      const activeBtn : HTMLButtonElement = itemRefs[playerId]
      activeBtn.parentElement?.classList.remove('selected')
      activeBtn.classList.remove('active')
      showNoteRef.value = false
    }

    const enterRoom = () => {
      if (playerIdRef.value !== null) {
        const playerName = playerNames[playerIdRef.value]
        // const uid = doc(collection(db, `games/${id}/players/`))
        // console.log("setPresenceState Start", id.toString(), playerIdRef.value, room)
        // setPresenceState(id.toString(), playerIdRef.value, +room)

        // check if all initial documents have been copied to «chats»
        const { error: colError, docExists, load: loadCheckExistenceOfColl } = useCheckExistenceOfColl(`/games/${id}/chats/`)

        loadCheckExistenceOfColl()

        watch(docExists, () => {
          showNoteRef.value = false
          console.log("docExists", docExists.value)
          if (!docExists.value) {
            modalContent.value = "InitializeInfo"
          } else {
            modalContent.value = null

            const user = auth.currentUser;
            if (user) {
              userStore.setUid(user.uid)
            }
            userStore.setPlayerId(playerIdRef.value?.toString())
            userStore.setPlayerName(playerName)
            userStore.setIsMobile(mobileDevice)
            userStore.setLang(lang)
            setTimeout(() => {
              router.push({
                name: 'Room',
                params: {lang, id, room}
              })
            }, 1000)
          }
          console.log("docExists", docExists)
          console.log("colError", colError)
          document.body.scrollTo(0,0)
        })
      } else {
        console.log("playerId is NULL")
      }
    }

    /* array of referencing items in v-for */
    let itemRefs = <any>[]
    const setItemRef = (el: HTMLImageElement) => {
      if (el) {
        itemRefs.push(el)
      }
    }

    return {enterRoom, playerIdRef, playerNames, playersRef, setItemRef, selectPlayer, resetPlayer, otherPlayersIndexRef, showNoteRef, modalContent}
  }
})


