export default {
    project: 'birthday-party',
    testing: false,
    rooms: 8,
    player: [
        {
            id: 0,
            name: 'lisa',
            img: 'https://firebasestorage.googleapis.com/v0/b/escape-if-you-can-01.appspot.com/o/images%2Fbirthday-party%2Fprofiles%2Flisa_profile.jpg?alt=media&token=b9cd5ef0-e647-44d9-bfb6-09360df250ea'
        },
        {
            id: 1,
            name: 'paul',
            img: 'https://firebasestorage.googleapis.com/v0/b/escape-if-you-can-01.appspot.com/o/images%2Fbirthday-party%2Fprofiles%2Fpaul_profile.jpg?alt=media&token=7157d5d9-21d0-41c7-937d-8a03e44c17ad'
        }
    ],
    profiles: {
        chrissy: {
            img: 'https://firebasestorage.googleapis.com/v0/b/escape-if-you-can-01.appspot.com/o/images%2Fbirthday-party%2Fprofiles%2Fchrissy_profile.jpg?alt=media&token=1a989a4d-9824-449f-b227-ba629bc0651f'
        }
    },
    websites: {
        "1": [
            {
                name: "ESC-Mail",
                path: "Escmail"
            },
            {
                name: "Google",
                path: "Google"
            },
        ],
        "2": [
            {
                name: "ESC-Mail",
                path: "Escmail"
            },
            {
                name: "Fotogram",
                path: "Fotogram"
            },
            {
                name: "Google Maps",
                path: "https://www.google.com/maps/d/embed?mid=1GTV2P6inBfMwb2NnPu6o4YkPKNhNE71Y&ehbc=2E312F"
            },
        ],
        "3": [
            {
                name: "ESC-Mail",
                path: "Escmail"
            },
            {
                name: "Fotogram",
                path: "Fotogram"
            },
            {
                name: "Google Maps",
                path: "https://www.google.com/maps/d/embed?mid=1GTV2P6inBfMwb2NnPu6o4YkPKNhNE71Y&ehbc=2E312F"
            },
        ],
        "4": [
            {
                name: "Fotogram",
                path: "Fotogram"
            },
            {
                name: "Show-Entertainment-Hamburg",
                path: "ShowEntertainmentHamburg"
            },
            {
                name: "Google Maps",
                path: "https://www.google.com/maps/d/embed?mid=1GTV2P6inBfMwb2NnPu6o4YkPKNhNE71Y&ehbc=2E312F"
            },
        ],
        "5": [
            {
                name: "Fotogram",
                path: "Fotogram"
            },
            {
                name: "Google Maps",
                path: "https://www.google.com/maps/d/embed?mid=1GTV2P6inBfMwb2NnPu6o4YkPKNhNE71Y&ehbc=2E312F"
            },
            {
                name: "Google",
                path: "Google"
            },
        ],
        "6": [
            {
                name: "Fotogram",
                path: "Fotogram"
            },
            {
                name: "Google Maps",
                path: "https://www.google.com/maps/d/embed?mid=1GTV2P6inBfMwb2NnPu6o4YkPKNhNE71Y&ehbc=2E312F"
            },
            {
                name: "Google",
                path: "Google"
            },
        ],
        "7": [
            {
                name: "Fotogram",
                path: "Fotogram"
            },
            {
                name: "Google Maps",
                path: "https://www.google.com/maps/d/embed?mid=1GTV2P6inBfMwb2NnPu6o4YkPKNhNE71Y&ehbc=2E312F"
            },
            {
                name: "Google",
                path: "Google"
            },
        ],
        "8": [
            {
                name: "Fotogram",
                path: "Fotogram"
            },
            {
                name: "Google Maps",
                path: "https://www.google.com/maps/d/embed?mid=1GTV2P6inBfMwb2NnPu6o4YkPKNhNE71Y&ehbc=2E312F"
            },
            {
                name: "Google",
                path: "Google"
            },
        ],
    },
    mail: {
        sender: "Lisa & Paul",
        address: "B-Day_25@escmail.com"
    },
    i18n: {
        de: {
            day: "Tag",
            timeElapsed: "Bisher benötigte Zeit",
            howToPlay: [
                {
                    topic: "Grundlagen",
                    content: [
                        "Stellt sicher, dass ihr mindestens <strong> zu zweit</strong> seid und vernetzt euch via Video-Chat oder Telefon",
                        "Schaltet den <strong>Ton</strong> auf eurem Endgerät ein",
                        "Schaut euch am besten zu Beginn eines jeden Rätsels erst einmal das <strong>Tagesziel</strong> an!",
                        "Untersucht die Räume und findet die <strong>Hinweise</strong>, die zum jeweiligen Rätsel gehören",
                        "Nutzt euer Gehirn und weitere <strong>Hilfsmittel</strong>, um die Rätsel zu lösen",
                        "Sprecht miteinander - ohne <strong>Kommunikation</strong> kommt ihr nicht ans Ziel!"
                    ],
                    icon: "&#xe904;"
                },
                {
                    topic: "Steuerung",
                    content: [
                        "Haltet die linke Maustaste gedrückt und bewegt den Pfeil in eine Richtung, um euch im Raum umzuschauen (bei Touchscreen per Finger)",
                        "Klickt auf Objekte im Raum, um diese genauer zu untersuchen",
                        "Unter <strong>Einstellungen</strong> könnt ihr euch die anklickbaren Objekte anzeigen lassen, falls ihr Schwierigkeiten habt sie zu finden (bei Touchscreen automatisch aktiviert)",
                        "Klickt neben ein geöffnetes Objekt, um dieses wieder zu schließen (bei Touchscreen auch über das X oben links möglich)",
                    ],
                    icon: "&#xebf6;"
                },
                {
                    topic: "Menü",
                    content: [
                        "Das kleine Kalendersymbol zeigt euch den <strong>Spieltag</strong> an, an dem ihr euch befindet",
                        "Öffnet das Listen-Symbol, um mehr über euer <strong>Tagesziel</strong> und euren <strong>Spielfortschritt</strong> zu erfahren, den <strong>Spieltag zu wechseln</strong>, oder wenn ihr einen <strong>Tipp</strong> benötigt"
                    ],
                    icon: "&#xec1d;"
                },
                {
                    topic: "Lösen",
                    content: [
                        "Nutzt das spielinterne Smartphone, um eure Lösungen einzugeben",
                        "Sobald ein Spieler die Lösung korrekt eingegeben hat wird der nächste Spieltag <strong>für alle Spieler freigeschaltet</strong>",
                        "Wechselt zum nächsten Rätsel, indem ihr auf den nun <strong>unterstrichenen Tag</strong> auf der To-Do-Liste klickt"
                    ],
                    icon: "&#xea5e;"
                },
                {
                    topic: "Genug für heute?",
                    content: [
                        "Dann schließt einfach den Browser. Euer Spielstand wird automatisch gespeichert und eure Spielzeit angehalten - bis zum nächsten Login!"
                    ],
                    icon: "&#xec68;"
                }
            ],
            getInfo: [
                "Über den <strong>Info-Punkt</strong> könnt ihr diese Informationen auch während des Spiels noch einmal abrufen!",
                "Die Zeit gibt an, wie lange ihr bereits Freude an diesem Spiel hattet 😉"
            ],
            fullScreen: [
                "Spielt am besten im Vollbildmodus"
            ],
            haveFun: "VIEL SPASS !!!",
            next: "weiter",
            howTo: "How to Play",
            settings: "Einstellungen",
            showHS: "Anklickbare Objekte anzeigen"
        }
    }
}
