

import {defineComponent} from 'vue';
import router from "@/router";
import Footer from "@/components/Footer.vue";
import Config from "@/config";
import {useRoute} from "vue-router";

export default defineComponent({
  components: {Footer},

  setup() {
    const route = useRoute()
    const config = Config
    const i18n = config.i18n.de
    const id = route.params.id
    const lang = route.params.lang as string || ''
    const goToStart = () => {
      console.log("lang", lang)
      console.log("id", id)

      router.push({
        name: 'Start',
        params: {lang, id}
      })
    }

    return {i18n, goToStart}
  }
})


