
import {defineAsyncComponent, defineComponent,  onMounted, onUnmounted, ref, shallowRef} from "vue";
import { useUserStore } from "@/store/useUserStore"

export default defineComponent({
  name: "Modal",
  props: {
    componentName: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: false
    },
    solutionChat: {
      type: String,
      required: false
    },
    solutionQuestion: {
      type: String,
      required: false
    },
    timestamp: {
      type: String,
      required: false
    }
  },
  setup(props, context) {
    const userStore = useUserStore()
    const isMobile = (userStore.isMobile === 'true')

    const modalCoomponent = ref(props.componentName)
    const component = shallowRef(defineAsyncComponent(() => import("@/components/modals/" + modalCoomponent.value + ".vue")))

    const changeComponent = (newComponentName: string) => {
      component.value = defineAsyncComponent(() => import("@/components/modals/" + newComponentName + ".vue"))
      modalCoomponent.value = newComponentName
      console.log('componentName', modalCoomponent.value)
    }

    const handleClose = () => {
      console.log("closeModal")
      context.emit('closeModal', null)
    }

    onMounted(() => {
      console.log("Modal mounted", modalCoomponent.value)
      document.body.classList.add('modal-open')
    })

    onUnmounted(() => {
      console.log("Modal unmounted", modalCoomponent.value)
      document.body.classList.remove('modal-open')
    })

    return {component, modalCoomponent, handleClose, changeComponent, isMobile}
  }
})
