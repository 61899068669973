import { ref } from 'vue'
import { auth } from '@/firebase/config'

// refs
const admin = ref(auth.currentUser)

// auth changes
auth.onAuthStateChanged(_admin => {
  console.log('User state change. Current user is:', _admin)
  admin.value = _admin
});

const getAdmin = () => {
  console.log("admin", {...admin.value})
  return { admin }
}

export default getAdmin
