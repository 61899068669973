
import {defineComponent, onMounted, reactive, ref, toRefs, watch} from "vue";
import getState from "@/composables/getState";
import {useRoute} from "vue-router";
import config from "@/config";
import getProgress from "@/composables/getProgress";
import {useUserStore} from "@/store/useUserStore";
import { isMobileOnly } from "mobile-device-detect";

export default defineComponent({
  name: "Navigation",
  props: {
    closeNav: {
      type: Boolean,
      required: false
    }
  },
  setup(props, context) {
    const route = useRoute()
    const id = route.params.id
    const roomId = route.params.room
    const playersRef = reactive(config.player as { [k: string]: any })
    const navRef = ref<HTMLElement | null>(null)
    const toggleNav = () => {
      navRef.value?.classList.toggle('open')
    }
    const closeNavRef = toRefs(props).closeNav
    const userStore = useUserStore()
    const playerId = +userStore.playerId
    const fullscreen = ref<boolean>(false)


    // add current online/offline state to playersRef
    const {error: errorState, documents: stateDoc, load} = getState(`/games/${id}/players/`)
    load()
    watch(stateDoc, () => {
      if (stateDoc.value && !errorState.value) {
        for (let key in playersRef) {
          playersRef[key].players = stateDoc.value.filter(item => item.state === 'online' && item.playerId === +key && item.room === +roomId).length
        }
      }
    })

    const {error: errorProgress, documents: progressDoc, load: loadProgress} = getProgress('/games/', `${id}`)
    loadProgress()
    watch(progressDoc, () => {
      if (progressDoc.value && !errorProgress.value) {
        // if process of current room is true (you made it :-)
        if (progressDoc.value?.progress && progressDoc.value.progress[+roomId]) {
          setTimeout( () => {
            navRef.value?.classList.add('open')
            navRef.value?.classList.add('solved')
          }, 12000)
        }
      }
    })

    watch(closeNavRef,() => {
      navRef.value?.classList.remove('open')
      navRef.value?.classList.remove('solved')
    })


    const openModal = (name: string) => {
      context.emit('openModal', name)
      navRef.value?.classList.remove('solved')
    }

    const toggleFullscreen = (event: HTMLElement) => {
      fullscreen.value = !fullscreen.value
      let element = document.documentElement;

      if (event instanceof HTMLElement) {
        element = event;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      element.requestFullscreen = element.requestFullscreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || function() {
        return false;
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.cancelFullscreen = document.cancelFullscreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || function() {
        return false;
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      isFullscreen ? document.cancelFullscreen() : element.requestFullscreen();
    }

    onMounted(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fullscreen.value = document.webkitIsFullScreen || document.mozFullScreen || false;
    })

    return {toggleNav, navRef, stateDoc, playersRef, roomId, openModal, playerId, toggleFullscreen, fullscreen, isMobileOnly}
  }
})
