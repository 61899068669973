import { createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "pano",
  ref: "pano"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_Audio = _resolveComponent("Audio")!
  const _component_AdminPano = _resolveComponent("AdminPano")!

  return (_openBlock(), _createElementBlock("main", null, [
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createElementVNode("div", _hoisted_1, null, 512)
    ])),
    _createVNode(_component_Navigation, {
      onOpenModal: _ctx.updateModal,
      closeNav: _ctx.closeNav
    }, null, 8, ["onOpenModal", "closeNav"]),
    (_ctx.modalContent)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "body"
        }, [
          _createVNode(_component_Modal, {
            componentName: _ctx.modalContent,
            path: _ctx.modalLink,
            solutionChat: _ctx.modalSolutionChat,
            solutionQuestion: _ctx.modalSolutionQuestion,
            timestamp: _ctx.modalTimestamp,
            onCloseModal: _ctx.updateModal,
            ref: "modal"
          }, null, 8, ["componentName", "path", "solutionChat", "solutionQuestion", "timestamp", "onCloseModal"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Audio, {
      audio: _ctx.audio,
      "play-audio": _ctx.playAudio
    }, null, 8, ["audio", "play-audio"]),
    ((!_ctx.isAnonymous && _ctx.viewRef))
      ? (_openBlock(), _createBlock(_component_AdminPano, {
          key: 1,
          view: _ctx.viewRef,
          viewer: _ctx.viewerRef,
          hotspots: _ctx.hotspotArrRef,
          "onUpdate:modelPlayer": _cache[0] || (_cache[0] = ($event: any) => (_ctx.playerIdRef = $event)),
          onLoadPanoData: _ctx.loadPano
        }, null, 8, ["view", "viewer", "hotspots", "onLoadPanoData"]))
      : _createCommentVNode("", true)
  ]))
}