import {ref} from "vue";
import Progress from "@/types/Progress";
import {auth, db, signInAnonymously} from "@/firebase/config";
import {doc, getDoc} from "firebase/firestore";
// import getAdmin from "@/composables/getAdmin";

const getGame = async (id: string) => {

  const error = ref<string | null>(null)
  let project: Progress | null = null
  const docRef = doc(db, 'games', id)

  const getGame = async (): Promise<Progress> => {
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      error.value = null
      return {...docSnap.data(), id: docSnap.id} as Progress
    } else {
      console.log("catch: The game does not exists");
      throw new Error('The game does not exists');
    }
  }

  try {
    // const { admin } = await getAdmin()
    // console.log("admin", {...admin.value})
    if (auth.currentUser && !auth.currentUser.isAnonymous) {
      project = await getGame();
    } else {
      await signInAnonymously(auth)
          .then(async () => {
            // Signed in..
            console.log("Signed in");
            project = await getGame();
          })
          .catch((err) => {
            auth.currentUser?.delete();
            throw new Error('Get Game Error: ' + err.code + " / " + err.message);
          });
    }
  } catch (err: any) {
    project = null
    error.value = 'The game does not exists: ' + err.message;
  }
  return {error, project}
}

export default getGame
