import { ref } from 'vue'
import {db} from '../firebase/config'
import Game from "@/types/Game"
import {doc, setDoc} from "firebase/firestore";


const updateGame = (coll: string) => {

  const error = ref<string | null>(null)

  // add a new document
  const updateGame = async (id: string, obj: Partial<Game> ) => {
    error.value = null

    try {
      const docRef = doc(db, coll, id)
      setDoc(docRef, obj, {merge: true})
    }
    catch(err) {
      if (err instanceof Error) {
        console.log(err.message)
        error.value = 'could not send the message'
      }
    }
  }

  return { error, updateGame }

}

export default updateGame
