import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'

export const useUserStore = defineStore('user',{
    state: () => ({
        uid: useSessionStorage('uid', ''),
        playerId: useSessionStorage('playerId', ''),
        playerName: useSessionStorage('playerName', ''),
        isMobile: useSessionStorage('isMobile', ''),
        lang: useSessionStorage('lang', ''),
        stripperConfig: useSessionStorage('stripperConfig', ''),
        showHS: useSessionStorage('showHS', ''),
        time: useSessionStorage('time', '')
    }),
    getters: {
        getUid(): string {
            return this.uid
        },
        getPlayerId(): string {
            return this.playerId
        },
        getPlayerName(): string {
            return this.playerName
        },
        getIsMobile(): string {
            return this.isMobile
        },
        getLang(): string {
            return this.lang
        },
        getStripperConfig(): string[] {
            return this.stripperConfig.split(",")
        },
        getShowHS(): string {
            return this.showHS
        },
        getTime(): string {
            return this.time
        }
    },
    actions: {
        setUid(uid ?: string) {
            if (!uid) return
            this.uid = uid
        },
        setPlayerId(playerId ?: string) {
            if (!playerId) return
            this.playerId = playerId
        },
        setPlayerName(playerName ?: string) {
            if (!playerName) return
            this.playerName = playerName
        },
        setIsMobile(isMobile ?: string) {
            if (!isMobile) return
            this.isMobile = isMobile
        },
        setLang(lang ?: string) {
            if (!lang) return
            this.lang = lang
        },
        setStripperConfig(stripperConfig ?: string[]) {
            if (!stripperConfig?.length) return
            this.stripperConfig = stripperConfig.join(",")
        },
        setShowHS(showHS ?: string) {
            if (!showHS) return
            this.showHS = showHS
        },
        setTime(time ?: string) {
            if (!time) return
            this.time = time
        }
    }
})
