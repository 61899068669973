// Create a reference to this user's specific status node.
// This is where we will store data about being online/offline.
// import { rtdb } from '@/firebase/config'
import {getDatabase, ref, onValue, onDisconnect, set, serverTimestamp} from "firebase/database";
import {auth} from "@/firebase/config";

const setPresenceState = (gameid: string, playerId: number, room: number, uId?: string) => {

    // Fetch the current user's ID from Firebase Authentication.
    const user = auth.currentUser;

    if (!user) return;
    const uid = user.uid || uId;

    console.log('User is signed in: ' + JSON.stringify(user, undefined, 2));
    console.log('Token expires at ' + new Date(JSON.parse(JSON.stringify(user)).stsTokenManager.expirationTime));


    // Create a reference to this user's specific status node.
    // This is where we will store data about being online/offline.
    const db = getDatabase();
    const userStatusDatabaseRef = ref(db, `/status/${gameid}/${uid}`);

    // We'll create two constants which we will write to
    // the Realtime database when this device is offline
    // or online.
        const isOfflineForDatabase = {
            state: 'offline',
            last_changed: serverTimestamp(),
            room: room,
            playerId
        };
        const isOnlineForDatabase = {
            state: 'online',
            last_changed: serverTimestamp(),
            room: room,
            playerId
        };

    // Create a reference to the special '.info/connected' path in
    // Realtime Database. This path returns `true` when connected
    // and `false` when disconnected.
    const connectedRef = ref(db, '.info/connected');
    onValue(connectedRef, (snapshot) => {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() == false) {
            return;
        }

        // Add this device to my connections list
        // this value could contain info about the device or a timestamp too
        set(userStatusDatabaseRef, isOnlineForDatabase);

        // If we are currently connected, then use the 'onDisconnect()'
        // method to add a set which will only trigger once this
        // client has disconnected by closing the app,
        // losing internet, or any other means.
        onDisconnect(userStatusDatabaseRef).set(isOfflineForDatabase).then(function () {
            // The promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect()
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

            // We can now safely set ourselves as 'online' knowing that the
            // server will mark us as offline once we lose connection.
            set(userStatusDatabaseRef, isOnlineForDatabase).then(function () {
                // When disconnected, remove this device
            });
        })
    })
}

export default setPresenceState

