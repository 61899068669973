

import router from "@/router";

declare global {
  interface Window {
    Marzipano: any;
  }
}

import {computed, defineComponent, ref, watch, onMounted} from 'vue';
import {onBeforeRouteUpdate, useRoute} from "vue-router";
import Config from '@/config'
import Pano from '@/types/Pano'
import Hotspot from '@/types/Hotspot'
import AdminPano from '@/components/AdminPano.vue'
import getAdmin from '@/composables/getAdmin'
import getRoom from "@/composables/getRoom";
import Navigation from "@/components/Navigation.vue";
import Modal from "@/components/Modal.vue";
import Audio from "@/components/Audio.vue"
import { useUserStore } from "@/store/useUserStore"
import updateGame from "@/composables/updateGame";
import setPresenceState from "@/composables/setPresenceState";
import {doc, getDoc} from "firebase/firestore";
import {db} from "@/firebase/config";

export default defineComponent({
  name: 'Room',
  inheritAttrs: false,
  components: {
    Navigation,
    AdminPano,
    Modal,
    Audio
  },
  beforeRouteEnter(to, from, next) {
    const userStore = useUserStore()
    const { updateGame: updateDoc } = updateGame('games')
    let progress = to.meta.projectProgress as Record<number, string> || {}
    let times = to.meta.times as Record<string, Record<string, any>> || {}
    const maxRooms = to.meta.projectMaxRooms as number
    const roomId = to.params.room as string
    const lang = to.params.lang
    const id = to.params.id as string
    const playerId = userStore.playerId
    const uid = userStore.getUid

    // if uid not exist: go Home
    if (!uid) {
      next({replace: true, name: 'Start', params: {lang, id}})
      return
    }

    // enter new or first room
    if (!progress || !progress[+roomId]) {
      progress = {...progress, [`${roomId}`]: false}
      if (progress[+roomId - 1] || roomId === '1') {
        updateDoc(to.params.id.toString(), {progress: progress})
      }
    }
    // check if room has been finished
    if (progress[+roomId]) {

      // if true then check if all rooms have been finished => go to End
      if (+roomId >= maxRooms) {
        next({replace: true, name: 'End', params: {lang, id}})
      }
      // if true: try next room
      const nextRoom = +roomId + 1
      next({replace: true, name: 'Room', params: {lang, id, room: nextRoom}})
    } else {
      // if false: check if last room has been finished
      if (roomId !== '1' && !progress[+roomId - 1]) {
        // if not: go to Start
        next({replace: true, name: 'Start', params: {lang, id}})
      } else {
        // if the statingTime for the room has not been set yet
        if (!Object.keys(times).length || times[+roomId]?.startingTime === undefined || times[+roomId]?.startingTime === null ) {
          let updateTimeObj = {}
          updateTimeObj = {times: {[+roomId]: {startingTime: new Date()}}}
          updateDoc(id.toString(), updateTimeObj)
        }

        setPresenceState(id, +playerId, +roomId, uid)

        // got to room
        next()
      }
    }
  },
  setup: function () {
    const userStore = useUserStore()
    const uid = userStore.getUid
    const playerId = userStore.playerId
    const playerName = userStore.playerName
    const isMobile = (userStore.isMobile === 'true')
    const showHS = (userStore.getShowHS === 'ON')
    const setShowHS = userStore.setShowHS
    const route = useRoute()
    const config = Config
    const playerNames = config.player.map(item => item.name)
    const room = route.params.room
    const projectName = config.project
    const viewRef = ref(null)
    const viewerRef = ref(null)
    let viewer: any = null
    let scenes: any = []
    const hotspotArrRef = ref<Hotspot[]>([])
    const playerIdRef = ref<number>(parseInt(playerId))
    const playerNameRef = ref<string>(playerName)
    const local = ref<boolean>(window.document.location.hostname === '192.168.178.32'  || window.document.location.hostname === 'localhost')
    const isAnonymous = ref<boolean | null>(true)

    // modal vars
    const closeNav = ref<boolean>(false)
    const modalContent = ref<string | null>(null)
    const modalLink = ref<string>()
    const modalSolutionChat = ref<string>('')
    const modalSolutionQuestion = ref<string>('')
    const modalTimestamp = ref<string>('')
    const audio = ref<string>('')
    const playAudio = ref(0)

    onBeforeRouteUpdate(async (to, from, next) => {
      const roomId = to.params.room as string
      const maxRooms = config.rooms
      const lang = to.params.lang
      const id = to.params.id as string
      const playerId = userStore.playerId

      // if roomsId higher maxRoom or uid does not exist: go Home
      if ((maxRooms && +roomId > +maxRooms) || !uid) {
        next({replace: true, name: 'Start', params: {lang, id}})
        return
      }

      if (roomId) {
        if (uid) {

          try {
            const docRef = doc(db, 'games', id)
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
              const times = docSnap.data().times

              // if the statingTime for the room has not been set yet
              if (!Object.keys(times).length || times[+roomId]?.startingTime === undefined || times[+roomId]?.startingTime === null) {
                let updateTimeObj = {times: {[+roomId]: {startingTime: new Date()}}}
                const {updateGame: updateDoc} = updateGame('games')
                await updateDoc(id, updateTimeObj)
              }
            }
          } catch (err: any) {
            console.log('The game does not exists: ' + err.message);
          }

          setPresenceState(id, +playerId, +roomId, uid)

          // continue to room
          next()
        } else {
          // if not: go to Start
          next({replace: true, name: 'Start', params: {lang, id}})
        }
      }
    });

    if (!playerId) {
      router.push({name: 'Start'})
      return
    }

    // check if user is logged in for administration (not anonymous)
    const { admin } = getAdmin()
    if (admin.value) {
      isAnonymous.value = admin.value.isAnonymous
    }

    const {
      error: errorRoom,
      documents: roomDocsRef,
      load: loadPano
    } = getRoom(`projects/${projectName}/rooms/`, "room", +room)
    loadPano()


    // Viewer options.
    const viewerOpts = {
      "settings": {
        "mouseViewMode": "drag",
        "autorotateEnabled": true,
        "fullscreenButton": true,
        "viewControlButtons": true
      }
    }

    const Marzipano = computed(() => {
      return window.Marzipano || {}
    })

    onMounted(() => {
      const panoElement = document.getElementById('pano');

      // Initialize viewer
      viewer = new Marzipano.value.Viewer(panoElement, viewerOpts);
      viewerRef.value = viewer

      // make hotspot visibls
      if (showHS) {
        document.body.classList.add('showHS')
      } else if (isMobile) {
        setShowHS('ON')
        document.body.classList.add('showHS')
      }
    })


    watch(roomDocsRef, () => {
      if (!errorRoom.value) {
        if (!roomDocsRef.value) {
          console.log("Error: Pano could not be loaded.")
          return
        }

        const roomData = JSON.parse(JSON.stringify(roomDocsRef.value))[0]

        // reset Array Hotspots
        hotspotArrRef.value.length = 0

        // Create scenes.
        scenes = roomData.panos.map((data: Pano, playerId: number) => {

          // load only relevant pano - except if user is locked in admin
          if ( playerId !== playerIdRef.value && isAnonymous.value && !local.value) return

          // Create source.
          const source = (isMobile) ? Marzipano.value.ImageUrlSource.fromString(data.urlMobile) : Marzipano.value.ImageUrlSource.fromString(data.url)

          // Create geometry.
          const geoWidth = (isMobile) ? [{width: 2560}] : [{width: 5120}]
          const geometry = new Marzipano.value.EquirectGeometry(geoWidth)

          const initialView = {
            yaw: data.yaw * Math.PI / 180,
            pitch: data.pitch * Math.PI / 180,
            fov: 120 * Math.PI / 180
          };

          const limiter = Marzipano.value.util.compose(
              Marzipano.value.RectilinearView.limit.traditional(1024, 100 * Math.PI / 180),
              Marzipano.value.RectilinearView.limit.pitch(data.pitchRange.min, data.pitchRange.max),
          )

          // Create view
          const view = new Marzipano.value.RectilinearView(initialView, limiter)

          // Create scene.
          const scene = viewer.createScene({
            source: source,
            geometry: geometry,
            view: view,
            pinFirstLevel: true
          })

          if (data.hotspots) {
            data.hotspots.forEach((item: Hotspot, index) => {
              const hsDiv = document.createElement('div')
              hsDiv.classList.add('hotspot')
              hsDiv.classList.add('hs-' + item.type)
              hsDiv.style.width = (isMobile) ? "50px" : item.width + "px"
              hsDiv.style.height = (isMobile) ? "50px" : item.height + "px"
              hsDiv.textContent = item.name
              hsDiv.setAttribute('data-name', item.name)
              scene.hotspotContainer().createHotspot(hsDiv, {
                yaw: item.yaw,
                pitch: item.pitch,
              }, { perspective: {
                radius: 1000
              }
              });
              hsDiv.addEventListener('click', function () {
                updateModal(item.type, item.path, roomData.addressee, roomData.question, roomData.timestamp.seconds.toString())
              })
              hotspotArrRef.value.push({...item, playerId, id: index})
            })
          }

          return {
            scene: scene,
            view: view
          }
        })


        viewer.stage().addEventListener('renderComplete', function (eventName: string, complete: boolean) {
          console.log("Render Complete", eventName, complete)
        });


        // Display scene.
        scenes[playerIdRef.value].scene.switchTo()
        viewRef.value = scenes[playerIdRef.value].view

        // Open phone on first room
        if (room === '1') {
          setTimeout(() => openModal('phone', 'chat-lisa-paul', roomData.addressee, '', roomData.timestamp.seconds.toString()), 2000)
        }
      }
    })

    watch(playerIdRef, () => {
      scenes[playerIdRef.value].scene.switchTo()
      viewRef.value = scenes[playerIdRef.value].view
      playerNameRef.value = playerNames[playerIdRef.value]
      userStore.playerId = playerIdRef.value.toString();
      userStore.playerName = playerNames[playerIdRef.value]
    })

    // open Modal
    const updateModal = (compName: string, path = '', solutionChat = '', solutionQuestion = '', timestamp = '') => {
      if (modalContent.value) {
        modalContent.value = null
        setTimeout(() => {
          openModal(compName, path, solutionChat, solutionQuestion, timestamp)
        }, 0)
      } else {
        openModal(compName, path, solutionChat, solutionQuestion, timestamp)
      }
    }

    const openModal = (compName: string, path = '', solutionChat = '', solutionQuestion = '', timestamp = '') => {
      switch (compName) {
        case "phone":
          modalContent.value = 'Phone'
          modalSolutionChat.value = solutionChat
          modalSolutionQuestion.value = solutionQuestion
          modalTimestamp.value = timestamp
          modalLink.value = path
          break
        case "image":
          modalContent.value = "Image"
          modalLink.value = path
          break;
        case "carousel":
          modalContent.value = "Carousel"
          modalLink.value = path
          break;
        case "audio":
          playAudio.value++
          audio.value = path
          break;
        case "component":
          modalContent.value = path
          break;
        case "browser":
          modalContent.value = "Browser"
          modalLink.value = path
          break;
        default:
          modalContent.value = compName
      }

      // close navigation when modal opens
      if(compName && compName !== 'audio' && isMobile) {
        closeNav.value = !closeNav.value
      }
    }

    return {viewRef, viewerRef, playerNameRef, playerIdRef, hotspotArrRef, loadPano, isAnonymous, local, updateModal, closeNav, modalContent, modalLink, modalSolutionChat, modalSolutionQuestion, modalTimestamp, audio, playAudio}
  }
})
